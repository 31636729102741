<template>
  <generic-modal :opened="opened" @close="$emit('close')">
    <template v-slot:header>
      <div class="modal-box__header">
        <div class="modal-box__title">Сообщение об ошибке</div>
      </div>
      <button @click="$emit('close')" class="link link--gray modal-close" type="button">
        <svg-icon name="cross" :size="24" />
      </button>
    </template>

    <template v-slot:body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__top">
            <div class="description">
              Для уточнения сведений, введите описание ошибки и правильное значение показателя в
              поле для сообщения и укажите электронный адрес для ответа
            </div>
          </div>
          <form @submit.prevent="submit" id="report-error-form" class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <input-text
                    label="Фамилия, имя, отчество"
                    v-model="form.name"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="Организация"
                    v-model="form.organization"
                    :has-error="vuelidate.form.organization.$error"
                    @change="vuelidate.form.organization.$touch"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="E-mail"
                    v-model="form.email"
                    :has-error="vuelidate.form.email.$error"
                    :vuelidate-errors="vuelidate.form.email.$errors"
                    @change="vuelidate.form.email.$touch"
                    placeholder="example@mail.com"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="Номер телефона"
                    v-model="form.phoneNumber"
                    :has-error="vuelidate.form.phoneNumber.$error"
                    :vuelidate-errors="vuelidate.form.phoneNumber.$errors"
                    @change="vuelidate.form.message.$touch"
                    placeholder="+79011234567"
                />
              </div>
              <div class="col col--full">
                <file-attach label="Вспомогательные файлы" v-model="form.file" />
              </div>
              <div class="col col--full">
                <text-area
                    label="Сообщение об ошибке"
                    :has-error="vuelidate.form.message.$error"
                    :vuelidate-errors="vuelidate.form.message.$errors"
                    @change="vuelidate.form.message.$touch"
                    v-model="form.message"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <custom-button form="report-error-form" button-type="submit" label="Отправить" />
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@/utils/validators';
import GenericModal from '@/components/modals/GenericModal.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import InputText from '@/components/InputText.vue';
import FileAttach from '@/components/FileAttach.vue';
import TextArea from '@/components/TextArea.vue';
import CustomButton from '@/components/CustomButton.vue';
import axios from '@/api/axios';

export default {
  name: 'ReportErrorCaptcha',
  components: {
    GenericModal,
    SvgIcon,
    InputText,
    FileAttach,
    TextArea,
    CustomButton,
  },
  emits: ['close'],
  setup() {
    return {
      vuelidate: useVuelidate(),
    };
  },
  data() {
    return {
      form: {
        name: '',
        organization: '',
        email: '',
        phoneNumber: '',
        file: null,
        message: '',
        system: 'isga'
      },
    };
  },
  validations() {
    return {
      form: {
        email: { required, email },
        organization: { required },
        phoneNumber: { required },
        message: { required },
      },
    };
  },
  methods: {
    async submit() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      await axios.post('/report-error', this.getFormData());
      this.$emit('close');
      this.resetForm();
    },
    resetForm() {
      this.form = {
        name: '',
        organization: '',
        email: '',
        phoneNumber: '',
        file: null,
        message: '',
        system: 'isga'
      };

      this.vuelidate.form.$reset();
    },
    getFormData() {
      const formData = new FormData();
      formData.append('email', this.form.email);
      formData.append('system', this.form.system);
      formData.append('phoneNumber', this.form.phoneNumber);
      formData.append('system', this.form.system);
      if (this.form.file) {
        formData.append('file', this.form.file);
      }
      formData.append('message', this.form.message);
      return formData;
    },
  },
  props: {
    opened: Boolean,
  },
};
</script>
