import axios from '@/api/axios';

export const AccreditationRegisterRepository = {

    /**
     * Получить список субъектов РФ
     * @returns {Promise<Array>} Список субъектов
     */
    async getSubjects() {
        const response = await axios.get('register/subjects');
        return response.data;
    },

    /**
     * Получить список субъектов РФ
     * @returns {Promise<Array>} Список субъектов
     */
    async getOrganizationTypes() {
        const response = await axios.get('register/organizationTypes');
        return response.data;
    },

    /**
     * Получить список субъектов РФ
     * @returns {Promise<Array>} Список субъектов
     */
    async getOrganizationKinds() {
        const response = await axios.get('register/organizationKinds');
        return response.data;
    },

    /**
     * Получить список субъектов РФ
     * @returns {Promise<Array>} Список субъектов
     */
    async getCertificateStatuses() {
        const response = await axios.get('register/certificateStatuses');
        return response.data;
    },

    /**
     * Получить список субъектов РФ
     * @returns {Promise<Array>} Список субъектов
     */
    async getCertificateSupplementStatuses() {
        const response = await axios.get('register/certificateSupplementStatuses');
        return response.data;
    },

    /**
     * Получить список субъектов РФ
     * @returns {Promise<Array>} Список субъектов
     */
    async getEducationTypes() {
        const response = await axios.get('register/educationTypes');
        return response.data;
    },

    /**
     * Получить список субъектов РФ
     * @returns {Promise<Array>} Список субъектов
     */
    async getRegisterTable(request) {
        const response = await axios.post('register/getRegisterTable', request)
        return response.data;
    },

    async find(id) {
        const response = await axios.get(`register/certificate/${id}/`);
        return response.data;
    }
};
