import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { routes as AccreditationRegister } from './accredreestr';
import { routes as authRoutes } from './auth';
import { routes as userRoutes } from './user';
import { routes as expertRoutes } from './expert';
import { routes as errorRoutes } from './errors';
import { routes as registriesRoutes } from './registries';
import { routes as externalRoutes } from './external';
import { routes as curatorRoutes } from './curator';

const defaultRoute = {
  path: '',
  beforeEnter() {
    return {
      path: store.getters.homePath,
    };
  },
};

const routes = [
  defaultRoute,
  ...authRoutes,
  ...AccreditationRegister,
  ...userRoutes,
  ...externalRoutes,
  ...curatorRoutes,
  ...expertRoutes,
  ...errorRoutes,
  ...registriesRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (process.env.VUE_APP_MAINTENANCE_MODE === 'true' && to.name !== 'maintenance') {
    return {
      name: 'maintenance',
    };
  }

  if (to.meta.requiresAuth && !store.getters.authenticated) {
    return {
      name: 'login',
      query: { redirect: to.fullPath },
    };
  }

  if (to.meta.role && to.meta.role !== store.state.user.role) {
    return {
      path: store.getters.homePath,
    };
  }
});

export default router;
