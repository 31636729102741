<template>
  <div style="margin: 2% 20%">
    <div class="row" style="display: flex; align-items: center;">
      <div style="flex: 0 0 auto; margin-right: 10px;">
        <img src="../../assets/images/logo_old.gif" alt="" style="height: 100px; width: auto;">
      </div>
      <div style="flex: 1; text-align: center;">
        <h1 style="font-size: 44px; margin: 0;">
          Сведения о выбранном свидетельстве
        </h1>
      </div>
    </div>

    <hr style="margin: 20px 0;">

    <div class="tbl-wrap">
      <table class="tbl">
        <tbody>
        <tr>
          <td colspan="2">
            <b>Сведения об образовательной организации или организации, осуществляющей обучение</b>
          </td>
        </tr>
        <tr>
          <td>Субъект РФ</td>
          <td>{{ application.region }}</td>
        </tr>
        <tr>
          <td>Полное наименование организации</td>
          <td>{{ application.organizationName }}</td>
        </tr>
        <tr>
          <td>Сокращённое наименование организации</td>
          <td>{{ application.organizationShortName }}</td>
        </tr>
        <tr>
          <td>Адрес</td>
          <td>{{ application.address }}</td>
        </tr>
        <tr>
          <td>ИНН</td>
          <td>{{ application.inn }}</td>
        </tr>
        <tr>
          <td>Регистрационный номер</td>
          <td>{{ application.gosRegisterNumber }}</td>
        </tr>
        <tr>
          <td>Дата выдачи</td>
          <td>{{ application.date }}</td>
        </tr>
        <tr>
          <td>Номер свидетельства</td>
          <td>{{ application.registerNumber }}</td>
        </tr>
        <tr>
          <td>Серия и номер бланка</td>
          <td>{{ application.seriaNumber }}</td>
        </tr>
        <tr>
          <td>Дата окончания действия</td>
          <td>{{ application.dateEnd }}</td>
        </tr>
        <tr>
          <td>Тип свидетельства</td>
          <td>{{ application.type }}</td>
        </tr>
        <tr>
          <td>Статус</td>
          <td>{{ application.status }}</td>
        </tr>
        <tr>
          <td>Контролирующий орган</td>
          <td>{{ application.controlOrgan }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-for="(supplement, index) in application.supplements" :key="index" class="tbl-wrap">
      <table class="tbl">
        <caption>Сведения о приложении</caption>
        <tbody>
        <tr>
          <td>Серия, номер бланка приложения</td>
          <td>{{ supplement.seriaNumber }}</td>
        </tr>
        <tr>
          <td>Текущий статус приложения</td>
          <td>{{ supplement.status }}</td>
        </tr>
        <tr>
          <td>Дополнительные сведения</td>
          <td>{{ supplement.additional }}</td>
        </tr>
        </tbody>
      </table>

      <table class="tbl">
        <caption>Аккредитованные образовательные программы</caption>
        <thead>
        <tr>
          <th>Код УГС</th>
          <th>Наименование УГС</th>
          <th>Код</th>
          <th>Наименование</th>
          <th>Уровень образования</th>
          <th>Приостановление</th>
          <th>Лишение</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(program, pIndex) in supplement.programs" :key="pIndex">
          <td>{{ program.UgsCode }}</td>
          <td>{{ program.UgsName }}</td>
          <td>{{ program.Code }}</td>
          <td>{{ program.Name }}</td>
          <td>{{ program.educationLevel }}</td>
          <td>{{ program.suspended }}</td>
          <td>{{ program.canceled }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { AccreditationRegisterRepository } from '@/repositories/AccreditationRegister/AccreditationRegister';

const props = {
  id: {
    type: String,
    required: true,
  },
};

const application = ref({});

onMounted(async () => {
  application.value = await AccreditationRegisterRepository.find(props.id);
});
</script>

<style scoped>
.row {
  display: flex;
  align-items: center;
}

.tbl-wrap {
  margin-top: 20px;
}

.tbl {
  width: 100%;
  border-collapse: collapse;
}

.tbl th, .tbl td {
  border: 1px solid #ddd;
  padding: 8px;
}

.tbl th {
  background-color: #f2f2f2;
}
</style>