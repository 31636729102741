<template>
  <custom-button label="Сообщить об ошибке" :type="2" @click="modalIsOpen = true" />
  <report-error-captcha :opened="modalIsOpen" @close="modalIsOpen = false" />
</template>

<script>
import CustomButton from '@/components/CustomButton';
import ReportErrorCaptcha from "@/components/ReportErrorCaptcha.vue";
export default {
  name: 'ReportErrorButtonCaptcha',
  components: { CustomButton, ReportErrorCaptcha },
  data() {
    return {
      modalIsOpen: false,
    };
  },
};
</script>
